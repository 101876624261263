<template>
  <div>
    <failed-payment-banner></failed-payment-banner>
    <div class="page-container payments">
      <div class="md-layout md-alignment-top-left">
        <div class="md-layout-item md-size-80" :class="{ 'md-size-100': mobile }">
          <div class="md-layout md-alignment-top-left ">

            <!-- DEFAULT PAYMENT METHOD -->
            <credit-card-card
                v-if="defaultPaymentMethod && defaultPaymentMethod.paymentMethod === 'Credit Card'"
                :card="defaultPaymentMethod"
                :key="defaultPaymentMethod.id"
                :primary="true"
            ></credit-card-card>
            <bank-account-card
                v-if="defaultPaymentMethod && defaultPaymentMethod.paymentMethod === 'Bank Account'"
                :bank-account="defaultPaymentMethod"
                :primary="true"
            ></bank-account-card>

            <!-- CREDIT CARDS-->
            <credit-card-card
                v-for="card in notPrimaryCards"
                :key="card.id"
                :card="card"
            ></credit-card-card>

            <!-- BANK ACCOUNTS -->
            <bank-account-card
                v-for="bankAccount in notPrimaryBankAccounts"
                :key="bankAccount.id"
                :bankAccount="bankAccount"
            ></bank-account-card>

            <!-- ADD PAYMENT METHOD -->
            <div class="md-layout-item md-size-50" :class="{ 'md-size-100': mobile }">
              <div
                  class="md-card new-card-btn-div"
                  @click="addPaymentPage"
              >
                <span><md-icon>credit_card</md-icon> Add payment method</span>
              </div>
            </div>


            <div
                class="md-layout-item md-size-50"
                v-if="!(bankAccounts.length + creditCards.length) % 2 === 0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import CreditCardCard from './CreditCardCard'
    import BankAccountCard from './BankAccountCard'
    import FailedPaymentBanner from "./FailedPaymentBanner"
    export default {
        name: "PaymentMethods",
        components: { CreditCardCard, BankAccountCard, FailedPaymentBanner },
        data() {
            return {
                mobile: false
            }
        },
        async created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            // if(!this.creditCards.length || this.creditCards.length === 0){
                await this.fetchCreditCards();
            // }
            // if(!this.bankAccounts.length || this.bankAccounts.length === 0){
                await this.fetchBankAccounts();
            // }
            await this.fetchDefaultPaymentMethod()
        },
        computed: {
            ...mapGetters([
                'creditCards',
                'bankAccounts',
                'defaultPaymentMethod'
            ]),
            notPrimaryCards(){
                if(this.defaultPaymentMethod){
                    if(this.defaultPaymentMethod.paymentMethod !== 'Credit Card') return this.creditCards
                }

                return this.creditCards.filter(card => card.id !== this.defaultPaymentMethod.id)
            },
            notPrimaryBankAccounts(){
                if(this.defaultPaymentMethod){
                    if(this.defaultPaymentMethod.paymentMethod !== 'Bank Account') return this.bankAccounts
                }

                return this.bankAccounts.filter(bankAccount => bankAccount.id !== this.defaultPaymentMethod.id) // NON-DEFAULTS AREN'T ASSOCIATED TO AN ACCOUNT
            }
        },
        mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        },
        methods: {
            ...mapActions([
                'fetchCreditCards',
                'fetchBankAccounts',
                "fetchDefaultPaymentMethod",
            ]),
            addPaymentPage() {
                this.$router.push({
                    name: 'settings.new-payment-method'
                })
            }
        }

  }
</script>

<style lang="scss" scoped>
    .md-layout-item { /*margin: 20px 10px 0 10px;*/
        .new-card-btn-div{
            margin-top: 20px;
            height: 165px;
            cursor: pointer;
           
            border: 2px dashed rgba(0,0,0,0.12);
            box-shadow: none;
            text-align: center;
            line-height: 165px;
            color: var(--md-theme-default-primary);
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                font-weight: bold;
            }
            .md-icon{
                color: var(--md-theme-default-primary);
            }

        }
        .new-card-btn-div:hover{
            background-color: rgba(153, 153, 153, 0.2);
        }
    }
    .md-app-content .md-card{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: -1px;
    }
    .hr{
        border-bottom:1px solid #eee;
    }
    .md-app .md-content.md-theme-default{
        margin-left: 11px;
        margin-right: 11px;
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

        .new-card-btn-div{
            margin-left: 10px!important;
        }
    }

</style>