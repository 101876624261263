<template>
    <div class="md-layout-item md-size-50 credit-card" :class="{ 'md-size-100': mobile }">
        <div class="md-card payment">
            <md-card-content class="content">
                <div class="expired" v-if="expired">
                    Card is expired. <span v-if="primary">Please edit or add a new card.</span><span v-else>Please edit or remove.</span>
                </div>
                <div class="icon">
                <md-icon class="md-size-3x">credit_card</md-icon>
                </div>
                <div class="card-info">
                    <p class="cc-header">{{card.type}} •••• {{card.endNumbers}}</p>
                    <p>Expires: {{card.expireDate | ccDate}}</p>
                </div>
            </md-card-content>
            <div class="hr"></div>
            <md-card-actions class="spread-buttons">
                <md-button
                        @click="updateDefaultPaymentMethod({paymentMethod: 'Credit Card', id: card.id})"
                        v-if="!primary"
                        :disabled="disableSetPrimary"
                >
                    Set Primary
                </md-button>

                <div class="primary-label" v-if="primary === true">
                    <span class="md-body-2">Primary</span>
                </div>

                <div>
                    <md-button
                            v-if="!this.primary"
                            class="md-primary"
                            @click="deleteCreditCard(card.id)"
                    >Remove</md-button>
                    <md-button
                        v-if="failedPayments && failedPayments.length"
                        class="md-primary"
                        :to="{name: 'settings.new-payment-method'}"
                    >Invalid Card</md-button>
                    <md-button
                        v-else
                        class="md-primary"
                        @click="showEdit = true"
                    >Edit</md-button>
                </div>

            </md-card-actions>

        </div>

        <!-- EDIT CARD FORM-->
        <md-dialog :md-active="showEdit" @md-clicked-outside="showEdit = false" :class="{'blur': showSpinner, 'md-dialog-address-form-active': showEditAddress}">
            <md-dialog-title>Update your credit card</md-dialog-title>
            <md-dialog-content>
                <p><md-icon>credit_card</md-icon> {{card.type}} •••• {{card.endNumbers}}</p>
                <form @submit.prevent="submitUpdate" v-if="!updateSuccess">
                    <div class="md-layout md-gutters">
                        <md-field class="md-layout-item" :class="getValidationClass('cardMonth')">
                            <label for="cardMonth">MM</label>
                            <md-input v-model="cardMonth" name="cardMonth" id="cardMonth" @keydown="limit( $event, 'cardMonth', 2)" />
                            <span class="md-error" v-if="!$v.cardMonth.required">required</span>
                        </md-field>
                        
                        <md-field class="md-layout-item year" :class="getValidationClass('cardYear')">
                            <label for="cardYear">YY</label>
                            <md-input v-model="cardYear" @keydown="limit( $event, 'cardYear', 2)" name="cardYear" id="cardYear"/>
                        </md-field>
                    </div>

                    <p
                            style="cursor: pointer"
                            v-if="!showEditName"
                            @click="showEditName = true"
                    >
                        <md-icon>person</md-icon> {{ card.nameOnCard }}&nbsp;&nbsp;<md-icon >edit</md-icon>
                    </p>
                    <md-field v-if="showEditName">
                        <label for="name">Cardholder name</label>
                        <md-input v-model="nameOnCard" name="name" id="name" />
                    </md-field>

                    <p
                            style="cursor: pointer"
                            v-if="!showEditAddress"
                            @click="showEditAddress = true"
                    >
                        <md-icon>location_on</md-icon>{{ card.zipCode }}&nbsp;&nbsp;<md-icon>edit</md-icon>
                    </p>

                    <div v-if="showEditAddress">
                        <span class="md-body-2">Edit billing address</span>
                        <md-field :class="getValidationClass('address_line1')">
                            <label>Address line 1</label>
                            <md-input v-model="address_line1" type="text"></md-input>
                            <span class="md-error" v-if="!$v.address_line1.required">Address line 1 is required</span>
                        </md-field>

                        <md-field>
                            <label>Address line 2</label>
                            <md-input v-model="address_line2" type="text"></md-input>
                        </md-field>

                        <md-field :class="getValidationClass('address_city')">
                            <label>City</label>
                            <md-input v-model="address_city" type="text"></md-input>
                            <span class="md-error" v-if="!$v.address_city.required">City is required</span>
                        </md-field>

                        <md-field :class="getValidationClass('address_state')">
                            <label>State</label>
                            <md-input v-model="address_state" type="text"></md-input>
                            <span class="md-error" v-if="!$v.address_state.required">State is required</span>
                        </md-field>

                        <md-field :class="getValidationClass('address_zip')">
                            <label>ZIP code</label>
                            <md-input v-model="address_zip" type="text"></md-input>
                            <span class="md-error" v-if="!$v.address_zip.required">ZIP is required</span>
                        </md-field>
                    </div>

                    <div v-if="failedPayments && failedPayments.length">
                        <p style="color: #FC3841">This card will be set as your default card, and the previous failed payment totaling ${{ failedPayments[0].amount }} will be charged. <router-link :to="{ name: 'settings.payment-receipt', params: {paymentId: failedPayments[0].id} }" target="_blank">View payment details</router-link></p>
                    </div>

                    <md-dialog-actions v-if="!updateSuccess">
                        <div >
                            <md-button class="md-primary" @click="showEdit = false">Cancel</md-button>
                            <md-button type="submit" class="md-primary md-raised">Update</md-button>
                        </div>
                    </md-dialog-actions>

                </form>

                <div v-if="updateSuccess">
                   <p> Credit card updated.</p>

                    <md-dialog-actions >
                        <md-button v-if="updateSuccess" class="md-primary md-raised" @click="showEdit = false">Close</md-button>
                    </md-dialog-actions>
                </div>

                <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="showSpinner"></md-progress-spinner>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import { validationMixin } from 'vuelidate'
    import {required, maxLength, maxValue, minValue} from 'vuelidate/lib/validators'
    import {isAfter} from 'date-fns'
    export default {
        name: "BankAccountCard",
        props: {
            card: { type: Object},
            primary: Boolean
        },
        filters: {
            ccDate(dateString){
                return dateString.slice(5, 7) + '/' + dateString.slice(2, 4);
            }
        },
        data(){
            return {
                showEdit: false,
                showEditName: false,
                showEditAddress: false,
                updateSuccess: false,

                cardMonth: null,
                cardYear: null,
                // CVC: null,
                nameOnCard: null,

                address_line1: '',
                address_line2: '',
                address_city: '',
                address_state: '',
                address_zip: '',
                address_country: 'US',

                showSpinner: false,
                error: '',

                expired: false,
            }
        },
        computed: {
            ...mapGetters([
                'failedPayments'
            ]),
            disableSetPrimary() {
                if(this.failedPayments && this.failedPayments.length){
                  return true;
                }  else if(this.expired){
                  return true;
                }
                return false;
            }
        },
        created(){
            if( this.windowWidth  <= 760 ) {
                this.mobile = true
            }
        },
        mounted() {
            this.cardYear = this.card.expireDate.slice(2, 4)
            this.cardMonth = this.card.expireDate.slice(5, 7)
            this.nameOnCard = this.card.nameOnCard
            this.address_zip = this.card.zipCode

            if(isAfter(new Date(), new Date(this.card.expireDate))){
                this.expired = true;
            }

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        },
        validations(){
            if(this.showEditAddress && this.showEditName){
                return {
                    address_line1: { required },
                    address_city: { required },
                    address_state: { required },
                    address_zip: { required },
                    cardMonth: { required, minValue: minValue(1), maxValue: maxValue(12) },
                    cardYear: { required, minValue:  minValue(20)},
                    nameOnCard: {required}
                }
            }

            if(this.showEditAddress){
                return {
                    address_line1: { required },
                    address_city: { required },
                    address_state: { required },
                    address_zip: { required },
                    cardMonth: { required, minValue: minValue(1), maxValue: maxValue(12) },
                    cardYear: { required, minValue: minValue(20) },
                }
            }

            if(this.showEditName){
                return {
                    cardMonth: { required, minValue: minValue(1), maxValue: maxValue(12) },
                    cardYear: { required },
                    nameOnCard: {required}
                }
            }

            return {
                cardMonth: { required, minValue: minValue(1), maxValue: maxValue(12) },
                cardYear: { required },
            }
        },
        mixins: [validationMixin],
        methods: {
            ...mapActions([
                'deleteCreditCard',
                'updateDefaultPaymentMethod',
                'updateCreditCard',
                'retryPayment',
                'fetchCampaigns',
                'fetchSubscriptions'
            ]),
            getValidationClass (fieldName) {
                const field = this.$v[fieldName]
                // console.log('get validation class', field, fieldName);

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            async submitUpdate(e){
                e.preventDefault();
                // console.log('update clicked!');
                this.$v.$touch()
                if (this.$v.$anyError || this.$v.$invalid) {
                    console.error('Error submitting form:', this.$v.anyError, this.$v.$invalid);
                    return
                }

                this.showSpinner = true;

                const data = {}

                const expireDate = new Date(parseInt(`20${this.cardYear}`), parseInt(this.cardMonth), 0);
                const formattedExpireDate = expireDate.getUTCFullYear() + '-' + (parseInt(expireDate.getUTCMonth()) + 1) + '-' + expireDate.getUTCDate();

                data.expireDate = expireDate
                data.cvc = this.CVC

                if(this.showEditName)
                    data.nameOnCard = this.nameOnCard

                if(this.showEditAddress){
                    data.address_line1 = this.address_line1
                    data.address_line2 = this.address_line2
                    data.address_city = this.address_city
                    data.address_state = this.address_state
                    data.address_zip = this.address_zip
                }

                try {
                    const updatedCard = await this.updateCreditCard({id: this.card.id, cardData: data})

                    if(this.failedPayments && this.failedPayments.length){
                      // RETRY PAYMENT
                      await this.updateDefaultPaymentMethod({paymentMethod: 'Credit Card', id: this.card.id})
                      await this.retryPayment(this.failedPayments[0].id)
                      await this.fetchCampaigns()
                      await this.fetchSubscriptions();
                    }


                    this.updateSuccess = true;
                    this.showSpinner = false;
                }catch(e){
                    console.error(e);
                    this.error = 'Something went wrong. Please contact support if it persists'
                    this.showSpinner = false
                    throw e;
                }


            },
            limit( event, dataProp, limit ) {
                // console.log(event);
                if (
                    this[dataProp].length >= limit &&
                    event.key !=="Backspace" &&
                    event.key !== "Tab" &&
                    event.key !== "Shift" &&
                    event.key !== "ArrowRight" &&
                    event.key !== "ArrowLeft"
                ) {
                    // console.log('over limit of ', limit);
                    event.preventDefault();
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    .md-dialog{
        top:223px;
        max-width: 508px;
        border-radius: 10px;
        .md-dialog-content{
            padding-bottom: 0;
        }
    }
    .md-dialog-address-form-active {
      top: 400px;
    }
    .md-layout-item {
        height: 165px;
        margin-bottom: 16px;
        .payment{
            height: 165px;
            .md-card-content{
                padding-bottom: 0;
                .card-info{
                    display: inline-block;
                    margin-left: 10px;
                    .cc-header {
                        font-weight: bold;
                        font-size: 20px;
                    }
                    p{
                        margin: 2px 0px 0;
                    }
                }
            }

            .md-card-actions{
                .primary-label {
                    display: inline-block;
                    line-height: 46px;
                    margin-left: 15px;
                }
            }

        }

        .new-card-btn-div{
            cursor: pointer;
            background-color: whitesmoke;
            border: 2px #888 dashed;
            box-shadow: none;
            text-align: center;
            line-height: 165px;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                font-weight: bold;
            }

        }
        .new-card-btn-div:hover{
            background-color: rgba(153, 153, 153, 0.2);
        }

        .spread-buttons {
            justify-content: space-between;
        }
    }
   

    form{
        .md-layout{
            .md-layout-item{
                max-height: 48px;
            }
        }

    }
     .hr{
        border-bottom:1px solid #eee;
    }
     .payment{
        .md-card{
            padding-bottom: 10px;
            margin-top: 20px;
            min-height: 240px;
        }
        .content{
            min-height: 100px
        }
        .md-layout-item.left{
            float: left;
        }
        .content{
            width: 100%;
            position: relative;
            display: inline-block
        }
        .payment-results{
            display: inline-flex;

        }
        table{
   
        }
        table tr{
            text-align: left
        }
        .right{
            text-align: right
        }
        .md-card-content{
            min-height: 109px;
            display: block;
        }
        .md-card-actions{
            padding-right: 20px;
            padding-top: 9px;
            padding-bottom: 2px;
        }
    }
    .year{
        margin-left:8px;
    }
    .md-dialog-actions{
        padding-top: 0px;
        padding-bottom: 20px;
        padding-right: 0px;
    }

    .spinner{
        top:50%;
        margin-top: -50px;
        left: 50%;
        margin-left: -40px;
        position: absolute;
    }
    .blur{
        filter: blur(1px);
    }

    .expired {
        background-color: #F4656B;
        color: #fff;
        /*position: relative;*/
        padding: 13px;
        margin: -16px;
        margin-bottom: 0;
    }


    @media only screen and (max-width: 760px),  
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .payments .md-card.payment{
            margin-left: 10px;
        }

        .year{
            margin-left: 0px;
        }
    }
</style>