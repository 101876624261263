<template>
    <div class="md-layout-item md-size-50 bank-account">
        <div class="md-card payment">
            <md-card-content class="content">
                <div class="icon">
                    <md-icon class="md-size-3x">account_balance</md-icon>
                </div>
                <div class="card-info">
                    <p class="cc-header">Bank •••• {{bankAccount.endNumbers}}</p>
                    <p>{{bankAccount.nameOnAccount}}</p>
                </div>
            </md-card-content>
            <div class="hr"></div>
            <md-card-actions class="spread-buttons bank-account-buttons">
                <md-button
                        @click="updateDefaultPaymentMethod({paymentMethod: 'Bank Account', id: bankAccount.id})"
                        v-if="!primary"
                >
                    Set Primary
                </md-button>

                <div class="primary-label" v-if="primary === true">
                    <span class="md-body-2">Primary</span>
                </div>

                <div>
                    <md-button
                            class="md-primary"
                            v-if="!primary"
                            @click="deleteBankAccount(bankAccount.id)"
                    >
                        Remove
                    </md-button>
                    <md-button
                            class="md-primary"
                            v-if="!bankAccount.verified"
                            @click="showVerifyForm = true"
                    >
                        Verify
                    </md-button>
                </div>

            </md-card-actions>
        </div>

        <md-dialog :md-active="showVerifyForm" class="deposits">
            <md-dialog-title>Verify deposits</md-dialog-title>

            <md-dialog-content>

                <p>You should have received 2 deposits on your bank statement in amounts less than $1.00 from <strong>AMTS</strong>. If you have not yet received try again later. Please enter the amounts you received below:</p>

                <form>

                    <md-field>
                        <label for="amount1">Select the 1st amount</label>
                        <md-select name="amount1" id="amount1" v-model="verifyAmount1">
                            <md-option
                                    v-for="value in verifyValues"
                                    :value="value"
                                    :key="value"
                            >
                                $0.{{value}}
                            </md-option>
                        </md-select>
                    </md-field>

                    <md-field>
                        <label for="amount2">Select the 2nd amount</label>
                        <md-select name="amount2" id="amount2" v-model="verifyAmount2">
                            <md-option
                                    v-for="value in verifyValues"
                                    :value="value"
                                    :key="value"
                            >
                                $0.{{value}}
                            </md-option>
                        </md-select>
                    </md-field>

                </form>

                <span style="color:red">{{verifyError}}</span>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button
                        class="md-primary"
                        @click="showVerifyForm = false"
                >Cancel</md-button>
                <md-button
                        class="md-primary md-raised"
                        @click="attemptVerification()"
                >Verify</md-button>
            </md-dialog-actions>
        </md-dialog>

    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    export default {
        name: "BankAccountCard",
        props: {
            bankAccount: { type: Object},
            primary: {type: Boolean, default: false}
        },
        computed: {
            verifyValues() {
                return (new Array(99 - 10 + 1)).fill(undefined).map((_, i) => i + 10);
            }
        },
        data(){
            return {
                showVerifyForm: false,
                verifyAmount1: null,
                verifyAmount2: null,
                verifyError: null
            }
        },
        methods: {
            ...mapActions([
                "deleteBankAccount",
                "verifyBankAccount",
                "updateDefaultPaymentMethod"
            ]),
            async attemptVerification(){
                try {
                    await this.verifyBankAccount({
                        verifyAmount1: this.verifyAmount1,
                        verifyAmount2: this.verifyAmount2,
                        id: this.bankAccount.id
                    });

                    this.showVerifyForm = false;
                }catch(e){
                    console.log('ERROR VERIFYING', e);
                    this.verifyError = e.message
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-dialog{
        max-width: 508px;
        .md-dialog-content{
            padding-bottom: 0;
        }
    }

    .md-layout-item {
        height: 165px; 

        .payment{
            height: 165px;
            .md-card-content{
                padding-bottom: 0;
                .card-info{
                    display: inline-block;
                    margin-left: 10px;
                    .cc-header {
                        font-weight: bold;
                        font-size: 20px;
                    }
                    p{
                        margin: 2px 0px 0;
                    }
                }
            }

            .md-card-actions{
                .primary-label {
                    display: inline-block;
                    line-height: 46px;
                    margin-left: 15px;
                }
            }

        }

        .new-card-btn-div{
            cursor: pointer;
            background-color: whitesmoke;
            border: 2px #888 dashed;
            box-shadow: none;
            text-align: center;
            line-height: 165px;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                font-weight: bold;
            }

        }
        .new-card-btn-div:hover{
            background-color: rgba(153, 153, 153, 0.2);
        }

        .spread-buttons {
            justify-content: space-between;
        }
    }
    .md-card-content{
        min-height: 109px;
        display: block;
    }
    .md-card-actions{
        padding-right: 20px;
        padding-top: 9px;
        padding-bottom: 2px;
    }
    .hr{
        border-bottom:1px solid #eee;
    }
    .deposits .md-dialog-actions{
        padding-bottom: 20px;
        padding-right: 20px;
    }
    
</style>